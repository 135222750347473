<template>
    <v-row class="fill-height pa-3">
      <v-col cols="12" md="12" class="fill-height">
        <v-card class="fill-height">
          <v-card-text class="d-flex align-center justify-center">
            <v-text-field
                v-model="search"
                dense
                hide-details
                label="Пошуку номенклатури"
                single-line
                placeholder="введіть текст для пошуку"
            />
            <v-btn depressed
                   class="ml-2"
                   color="secondary"
                   height="30" width="46" min-width="46">
              <v-icon>mdi-folder-plus-outline</v-icon>
            </v-btn>
            <v-btn depressed
                   class="ml-2"
                   color="success"
                   height="30" width="46" min-width="46">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text class="tree-container">
            <v-treeview
                dense
                hoverable
                activatable
                :items="items"
                :open="open"
                :search="search"
                :filter="filter"
                :active.sync="selection"
                return-object
                color="grey darken-3"
                class="tree-view"
                style="cursor: pointer !important; min-height: 30px !important; user-select:none;"
            >
              <template v-slot:prepend="{ item }">
                <v-icon :color="item.group ? 'secondary' : 'primary'" class="align-center">
                  {{ item.group ? 'mdi-folder' : 'mdi-format-list-bulleted' }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                <div class="d-flex align-center"
                     style="font-size: .78rem" v-if="!item.group"
                     @click="onTreeClick(item)"
                >
                  <div style="flex: 0 0 90px">
                    {{ item.code }}
                  </div>
                  <div style="flex: 1">
                    {{ item.name }}
                  </div>
                  <div style="flex: 0 0 90px">
                    {{ item.unit }}
                  </div>
                </div>
                <template v-else>
                  <div class="font-weight-bold"
                       style="font-size: .78rem"
                       @click="onTreeClick(item)"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'nomenclature_list_group',
  data: () => ({
    counter: 0,
    timer: null,
    activeItem: null,
    items: [
      {
        id: 1,
        group: true,
        name: 'Товари',
        children: [
          { id: 11, name: 'Газоблок', code: '00000001', unit: 'шт.' },
          { id: 12, name: 'Газоблок чорний 20*30', code: '00000002', unit: 'шт.' },
          { id: 13, name: 'Розетка подвійна', code: '00000003', unit: 'шт.' },
          { id: 14, name: 'Шнур войлочний', code: '00000004', unit: 'шт.' },
        ],
      },
      {
        id: 2,
        group: true,
        name: 'Послуги',
        children: [
          {
            id: 21,
            group: true,
            name: 'Послуги сторонніх організацій',
            children: [
              {id: 210, name: 'Прибирання', unit: 'грн.', code: '00000210'},
              {id: 211, name: 'Дератизація', unit: 'грн.', code: '00000211'}
            ]
          },
          {
            id: 22,
            group: true,
            name: 'Послуги власних працівників',
            children: [
              {
                id: 220,
                name: 'Інформаційні послуги',
                code: '000000220',
                unit: 'шт.'
              },
              {
                id: 221,
                name: 'Набирання тексту',
                code: '000000221',
                unit: 'шт.'
              },
            ]
          }
        ]
      }
    ],
    selection: [],
    open: [0],
    search: null,
    search_nomenclature: null,
    selected_row: 0
  }),
  computed: {
    filter () {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  methods: {
    onTreeClick(item) {
      this.counter++

      if (this.counter === 1) {
        this.activeItem = item
        this.timer = setTimeout(() => {
          this.counter = 0
          this.activeItem = null
        }, 350)
      } else {
        if (this.activeItem === item) {
          this.counter = 0
          clearTimeout(this.timer)
        } else {
          clearTimeout(this.timer)
          this.counter = 0
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}
.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
.tree-container {
  :deep(.v-treeview--dense .v-treeview-node__root) {
    min-height: 30px !important;
  }
}
</style>